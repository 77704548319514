import React from "react"
import { Text, useWindowDimensions, View } from "react-native"
import Colors from "../../constants/Colors"
import * as Device from "expo-device"

const SpecialText = ({ children }) => (
  <Text
    style={{
      fontWeight: "bold",
      fontStyle: "italic",
      backgroundColor: Colors.primary,
      paddingHorizontal: 8,
      paddingVertical: 5,
      borderRadius: 10,
      marginHorizontal: 3,
      color: "#fff",
    }}
  >
    {children}
  </Text>
)

export default function AppTransferScreen() {
  const DESKTOP = useWindowDimensions().width > 800
  const isIOS = Device.osName?.toUpperCase() === "IOS"

  return (
    <View
      style={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
        paddingHorizontal: DESKTOP ? "30%" : 15,
        backgroundColor: Colors.bg,
        gap: 25,
      }}
    >
      <Text
        style={{
          fontFamily: "IranSans-Medium",
          fontWeight: "bold",
          fontSize: 30,
          color: "#000000",
          textShadow:
            "-0.2px 0, 0 0.2px, 0.2px 0, 0 -0.2px, -0.2px -0.2px, 0.2px 0.2px, -0.2px 0.2px, 0.2px -0.2px",
          textAlign: "center",
        }}
      >
        پنل جدید از راه رسید!
      </Text>

      <Text
        style={{
          fontFamily: "IranSans-Medium",
          fontWeight: "bold",
          fontSize: 12,
          color: "#000000",
          lineHeight: 35,
          textAlign: "center",
        }}
      >
        در پنل جدید، شما به راحتی می‌توانید از امکانات متنوع استفاده کنید و از ویژگی‌های جدید
        بهره‌مند شوید. این پنل به گونه‌ای طراحی شده است که تجربه کاربری شما را بهبود بخشد و دسترسی
        به ابزارها و خدمات مختلف را آسان‌تر کند. با استفاده از این امکانات، می‌توانید کارهای خود را
        سریع‌تر و با دقت بیشتری انجام دهید.
      </Text>

      <View
        style={{
          width: "100%",
          padding: 20,
          borderRadius: 30,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: Colors.cardBg,
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontWeight: "bold",
            fontSize: 12,
            color: "#333",
            lineHeight: 35,
            textAlign: "center",
          }}
        >
          لطفاً مرورگر <SpecialText>{isIOS ? "Safari" : "Google Chrome"}</SpecialText> را باز کرده و
          آدرس <SpecialText>app.aminh.pro</SpecialText> را در نوار آدرس (Address Bar) وارد نمایید.
          سپس کلید Enter را فشار دهید یا روی دکمه برو (Go) کلیک کنید تا صفحه مورد نظر بارگذاری شود
        </Text>
      </View>

      <View
        style={{
          width: "100%",
          padding: 20,
          borderRadius: 30,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: Colors.cardBg,
        }}
      >
        <Text
          style={{
            fontFamily: "IranSans-Medium",
            fontWeight: "bold",
            fontSize: 12,
            color: "#333",
            lineHeight: 35,
            textAlign: "center",
          }}
        >
          پس از باز شدن آدرس مورد نظر، با استفاده از <SpecialText>شماره تلفن همراه</SpecialText> خود
          وارد سیستم شوید و از امکانات و خدمات جدید بهره‌مند شوید
        </Text>
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
}
