import React from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import ServicesScreen, {
  screenOptions as ServicesScreenOptions,
} from "../screens/Settings/ServicesScreen"
import CapabilityScreen, {
  screenOptions as CapabilityScreenOptions,
} from "../screens/Settings/CapabilityScreen"
import UsersManagementScreen, {
  screenOptions as UsersManagementScreenOptions,
} from "../screens/Settings/UsersManagementScreen"
import AddUserScreen, {
  screenOptions as AddUserScreenOptions,
} from "../screens/Settings/AddUserScreen"
import AnalyseUserScreen, {
  screenOptions as AnalyseUserScreenOptions,
} from "../screens/Settings/AnalyseUserScreen"
import UserProfileScreen, {
  screenOptions as UserProfileScreenOptions,
} from "../screens/Settings/UserProfileScreen"
import { useSelector } from "react-redux"
import CompanyReportsScreen, {
  screenOptions as CompanyReportsScreenOptions,
} from "../screens/Settings/CompanyReportsScreen"

const SettingsStackNavigator = createNativeStackNavigator()
const SettingsNavigator = () => {
  const capabilities = useSelector((store) => store.user.capabilities)
  const department = useSelector((store) => store.user.user_data.department)

  return (
    <SettingsStackNavigator.Navigator>
      <SettingsStackNavigator.Screen
        name="UserProfile"
        component={UserProfileScreen}
        options={{ ...UserProfileScreenOptions, title: "پروفایل" }}
      />
      <SettingsStackNavigator.Screen
        name="AnalyseUser"
        component={AnalyseUserScreen}
        options={{ ...AnalyseUserScreenOptions, title: "آنالیز پرسنل" }}
        initialParams={{ user_id: null }}
      />
      <SettingsStackNavigator.Screen
        name="UsersManagement"
        component={UsersManagementScreen}
        options={{ ...UsersManagementScreenOptions, title: "مدیریت پرسنل" }}
      />
      <SettingsStackNavigator.Screen
        name="AddUser"
        component={AddUserScreen}
        options={{ ...AddUserScreenOptions, title: "افزودن کاربر" }}
        initialParams={{ user_id: null }}
      />

      {capabilities.includes("services_add") && capabilities.includes("services_get_all") && (
        <SettingsStackNavigator.Screen
          name="Services"
          component={ServicesScreen}
          options={{ ...ServicesScreenOptions, title: "خدمات" }}
        />
      )}

      {capabilities.includes("capabilities_management") && (
        <SettingsStackNavigator.Screen
          name="Capability"
          component={CapabilityScreen}
          options={{ ...CapabilityScreenOptions, title: "دسترسی ها" }}
        />
      )}

      {department == "lead" && (
        <SettingsStackNavigator.Screen
          name="CompanyReports"
          component={CompanyReportsScreen}
          options={{ ...CompanyReportsScreenOptions, title: "گزارشات" }}
        />
      )}
    </SettingsStackNavigator.Navigator>
  )
}

export default SettingsNavigator
