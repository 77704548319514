import { useState } from "react"
import { View, Text, TouchableOpacity, StyleSheet, Image, Platform } from "react-native"
import Colors from "../constants/Colors"
import moment from "moment-jalaali"
import { Link } from "@react-navigation/native"
import { Ionicons } from "@expo/vector-icons"

const Stat = (props) => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Text
        style={{
          fontFamily: "IranSans-Medium",
          fontSize: 12,
          color: Colors.text,
          marginBottom: 3,
          direction: "rtl",
        }}
      >
        {props.title}
      </Text>
      <Text
        style={{
          fontFamily: "IranSans-Regular",
          fontSize: 10,
          color: Colors.secondText,
        }}
      >
        {props.subtitle}
      </Text>
    </View>
  )
}

const LeadCard = (props) => {
  const { item } = props
  const [statHovered, setStatHovered] = useState(false)
  const stageColor = props.stageColor ? props.stageColor : false
  const Click = Platform.OS == "web" ? Link : TouchableOpacity
  return (
    <Click
      style={{
        width: "100%",
        backgroundColor: "#F2F2F2",
        borderRadius: 15,
        marginVertical: 5,
        paddingVertical: 15,
        direction: "ltr",
        display: "flex",
        position: "relative",
        overflow: "hidden",
      }}
      onPress={Platform.OS == "web" ? null : props.onPress}
      to={Platform.OS != "web" ? null : props.onPress}
    >
      <View style={{ width: "100%" }}>
        <View style={{ width: "100%", flexDirection: "row-reverse" }}>
          <View
            style={{
              flex: 2,
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Text
              style={{
                transform: [{ rotate: "-90deg" }],
                fontFamily: "IranSans-Medium",
                fontSize: 12,
                color: Colors.secondText,
              }}
            >
              #{item.id}
            </Text>
          </View>
          <View
            style={{
              flex: 6,
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Text
              style={{
                color: Colors.text,
                fontFamily: "Iransans-Medium",
                fontSize: 12,
                marginVertical: 5,
              }}
            >
              {item.name}
            </Text>
            <Text
              style={{
                color: Colors.text,
                fontFamily: "Iransans-Light",
                fontSize: 12,
                marginVertical: 5,
              }}
            >
              {item.phone}
            </Text>
            <Text
              style={{
                color: Colors.text,
                fontFamily: "Iransans-Light",
                fontSize: 10,
                marginVertical: 7,
              }}
            >
              {item.services
                .replace("brand-registration", "ثبت مالکیت برند")
                .replace("brand-naming", "ایده پردازی اسم برند")
                .replace("company-registration", "ثبت شرکت")
                .replace("trade-license", "کارت بازرگانی")
                .replace("package-design", "بسته بندی")
                .replace("office-set", "ست اداری")
                .replace("_", " ")
                .replace("-", " ")
                .replace("course", "دوره")
                .replace("logomoon", "لوگومون")
                .replace("logo", "لوگو")
                .replace("site", "سایت")
                .replace("app", "اپلیکیشن")
                .replace("other", "موارد دیگر")}
            </Text>
          </View>
          <View
            style={{
              flex: 5,
              paddingLeft: 15,
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <View style={Styles.LeftSectionsContainer}>
              <View
                style={{
                  backgroundColor: stageColor ? stageColor : Colors.primary,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: 2,
                  borderRadius: 3,
                  width: 20,
                  marginLeft: 5,
                }}
              >
                <Text style={Styles.dateTitle}>ورود</Text>
              </View>
              <Text style={Styles.date}>
                {moment.unix(item.created_time).format("jYYYY/jM/jD HH:mm")}
              </Text>
            </View>

            <View
              style={{
                ...Styles.LeftSectionsContainer,
                // If we does not tousch
                opacity: item.touch_modified_time ? 100 : 0,
              }}
            >
              <View
                style={{
                  backgroundColor: stageColor ? stageColor : Colors.primary,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: 2,
                  borderRadius: 3,
                  width: 20,
                  marginLeft: 5,
                }}
              >
                <Text style={Styles.dateTitle}>تاچ</Text>
              </View>
              <Text style={Styles.date}>
                {moment.unix(item.touch_modified_time).format("jYYYY/jM/jD HH:mm")}
              </Text>
            </View>

            <View style={Styles.LeftSectionsContainer_staffs}>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: 3,
                }}
              >
                <Image
                  source={{ uri: item.responsible.avatar }}
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: 8,
                    marginRight: 3,
                  }}
                />
                <View>
                  <View
                    style={{
                      width: 16,
                      height: 16,
                      borderRadius: 8,
                      borderWidth: 1.4,
                      borderColor: Colors.text,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onMouseEnter={() => {
                      setStatHovered(true)
                    }}
                    onMouseLeave={() => {
                      setStatHovered(false)
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "IranSans-Medium",
                        fontSize: 11,
                        color: Colors.text,
                        marginTop: 1,
                      }}
                    >
                      {item.touches[0].total}
                    </Text>
                  </View>

                  {item.touches[0].total != 0 && (
                    <View
                      style={{
                        display: statHovered ? "flex" : "none",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                        left: 20,
                        width: 200,
                        height: "auto",
                        backgroundColor: Colors.cardBg,
                      }}
                    >
                      {item.touches.map((itemData, index) => (
                        <Stat
                          key={index}
                          title={
                            itemData.total == itemData.success
                              ? itemData.success
                              : `(${itemData.success}) ${itemData.total}`
                          }
                          subtitle={itemData.title}
                        />
                      ))}
                    </View>
                  )}
                </View>
                {item.source == "instagram" && (
                  <Ionicons name="logo-instagram" size={16} color={Colors.text} />
                )}
                {item.source == "telegram" && (
                  <View
                    style={{
                      width: 16,
                      height: 16,
                      borderRadius: 3,
                      backgroundColor: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        width: 13,
                        height: 13,
                        borderRadius: 6.5,
                        backgroundColor: Colors.text,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Ionicons name="ios-paper-plane-sharp" size={7} color={"#fff"} />
                    </View>
                  </View>
                )}
                {item.source == "landing" && item.medium == "direct" && (
                  <Ionicons name="arrow-up" size={16} color={Colors.text} />
                )}
                {item.source == "google" && (
                  <Ionicons name="md-logo-google" size={16} color={Colors.text} />
                )}
                {item.source == "wom" && (
                  <Ionicons name="md-heart-half-sharp" size={16} color={Colors.text} />
                )}
                {item.source == "ipm" && (
                  <Ionicons name="people-outline" size={16} color={Colors.text} />
                )}

                {item.medium == "dm" && (
                  <Ionicons name="paper-plane-outline" size={16} color={Colors.text} />
                )}
                {item.medium == "paid-ad" && (
                  <Ionicons name="cash-outline" size={16} color={Colors.text} />
                )}
                {item.medium == "call" && (
                  <Ionicons name="call-outline" size={16} color={Colors.text} />
                )}
                {item.medium == "seo" && (
                  <Ionicons name="search-outline" size={16} color={Colors.text} />
                )}
                {item.medium == "cpc" && (
                  <Ionicons name="hand-right-outline" size={16} color={Colors.text} />
                )}
              </View>
            </View>
          </View>
        </View>
      </View>

      {item.flag.id != 0 && item.flag && (
        <View
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: item.flag.color,
            borderBottomLeftRadius: 10,
            width: 30,
            height: 20,
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 2,
          }}
        ></View>
      )}
    </Click>
  )
}

const Styles = StyleSheet.create({
  dateTitle: {
    color: "#fff",
    fontFamily: "IranSans-Light",
    fontSize: 8,
  },
  LeftSectionsContainer: {
    width: "100%",
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
    marginVertical: 5,
  },
  LeftSectionsContainer_staffs: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginVertical: 5,
  },
  date: {
    color: Colors.text,
    fontFamily: "IranSans-Light",
    fontSize: 12,
  },
})

export default LeadCard
