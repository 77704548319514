import { useEffect, useState } from "react"
import { View, Text, Image, Dimensions, ScrollView, TouchableOpacity, Linking } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { createDrawerNavigator, DrawerItem } from "@react-navigation/drawer"
import Colors from "../constants/Colors"
import { Ionicons } from "@expo/vector-icons"
import { logOut } from "../components/Functions"

//Projects
import InactiveProjectsScreen, {
  screenOptions as InactiveProjectsScreenOptions,
} from "../screens/Project/InactiveProjectsScreen"
import ProjectsScreen, {
  screenOptions as ProjectsScreenOptions,
} from "../screens/Project/ProjectsScreen"
import ProjectsArchiveScreen, {
  screenOptions as ProjectsArchiveScreenOptions,
} from "../screens/Project/ProjectsArchiveScreen"

//EAM Navigator
import EAMNavigator from "./EAMNavigation"

//Sales Navigator
import SalesNavigator from "./SalesNavigation"

//CRM Navigator
import CRMNavigator from "./CRMNavigation"

//Support Navigator
import SupportNavigator from "./SupportNavigation"

//ASC Navigator
import ASCNavigator from "./ASCNavigation"

//Website Navigator
import WebsiteNavigator from "./WebsiteNavigation"

//Settings Navigator
import SettingsNavigator from "./SettingsNavigation"

//Other Screens
import BugsScreen, { screenOptions as BugsScreenOptions } from "../screens/BugsScreen"
import { useRoute } from "@react-navigation/native"
import { useRouter } from "expo-router"

const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const CustomDrawerContent = (props) => {
  const capabilities = useSelector((store) => store.user.capabilities)
  const user_data = useSelector((store) => store.user.user_data)
  const [activeMenu, setActiveMenu] = useState("")

  return (
    <View {...props} style={{ backgroundColor: Colors.text, height: "100%" }}>
      <View
        style={{
          marginTop: 100,
          paddingLeft: 25,
        }}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Image
            style={{ width: 100, height: 100, borderRadius: 50 }}
            source={
              user_data.avatar ? { uri: user_data.avatar } : require("../assets/img/logoR-500.png")
            }
          />
          <TouchableOpacity
            style={{
              flexDirection: "row-reverse",
              paddingVertical: 5,
              paddingLeft: 15,
              marginTop: 10,
              backgroundColor: "rgba(225,255,255,0.1)",
              borderRadius: 15,
              justifyContent: "center",
              alignItems: "center",
            }}
            onPress={() => {
              DESKTOP
                ? user_data.department == "lead"
                  ? null
                  : props.navigation.navigate("Settings", {
                      screen: "UserProfile",
                    })
                : alert("این صفحه فعلا فقط در دسکتاپ قابل مشاهده است")
            }}
          >
            <View
              style={{
                width: 20,
                height: 20,
                borderRadius: 10,
                marginHorizontal: 5,
                backgroundColor: "#fff",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Ionicons name="stats-chart" size={12} color={Colors.text} />
            </View>
            <Text
              style={{
                fontFamily: "IranSans-Regular",
                fontSize: 12,
                color: "#fff",
              }}
            >
              {user_data.name}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{ paddingTop: 40, paddingBottom: 100 }}>
          {(user_data.department == "lead" || capabilities.includes("eam_set_own_record")) && (
            <DrawerItem
              style={{
                backgroundColor: activeMenu.includes("EAM") ? "#fff" : "transparent",
              }}
              label={(props) => (
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: activeMenu.includes("EAM") ? Colors.text : "#fff",
                    marginRight: 15,
                    textAlign: "right",
                  }}
                >
                  تردد
                </Text>
              )}
              icon={() => {
                return (
                  <View
                    style={{
                      alignSelf: "center",
                      position: "absolute",
                      right: 20,
                    }}
                  >
                    <Ionicons
                      name="time-outline"
                      size={24}
                      color={activeMenu.includes("EAM") ? Colors.text : "#fff"}
                    />
                  </View>
                )
              }}
              labelStyle={{
                marginLeft: -20,
                fontFamily: "IranSans-Regular",
                fontSize: 12,
              }}
              onPress={() => {
                setActiveMenu(activeMenu.includes("EAM") ? "" : "EAM")
              }}
            />
          )}

          {activeMenu.includes("EAM") && (
            <View>
              {capabilities.includes("eam_set_own_record") && (
                <DrawerItem
                  style={{ marginTop: -10 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      ثبت ورود و خروج
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor: activeMenu == "EAM_setrecord" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("EAM_setrecord")
                    props.navigation.navigate("EAM", { screen: "SetRecord" })
                  }}
                />
              )}
              {(capabilities.includes("eam_leave_set") ||
                capabilities.includes("eam_leave_get_all")) && (
                <DrawerItem
                  style={{ marginTop: -10 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      ثبت مرخصی
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor: activeMenu == "EAM_grantdayoff" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("EAM_grantdayoff")
                    props.navigation.navigate("EAM", { screen: "GrantDayoff" })
                  }}
                />
              )}

              {capabilities.includes("eam_add_all_employee_record") && (
                <DrawerItem
                  style={{ marginTop: -10 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      کنترل تردد
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor: activeMenu == "EAM_records" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("EAM_records")
                    props.navigation.navigate("EAM", { screen: "Records" })
                  }}
                />
              )}
            </View>
          )}

          {capabilities.includes("project_get_own") && (
            <DrawerItem
              style={{
                backgroundColor: activeMenu.includes("Projects") ? "#fff" : "transparent",
              }}
              label={(props) => (
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: activeMenu.includes("Projects") ? Colors.text : "#fff",
                    marginRight: 15,
                    textAlign: "right",
                  }}
                >
                  پروژه ها
                </Text>
              )}
              icon={() => {
                return (
                  <View
                    style={{
                      alignSelf: "center",
                      position: "absolute",
                      right: 20,
                    }}
                  >
                    <Ionicons
                      name={"file-tray-full-outline"}
                      size={24}
                      color={activeMenu.includes("Projects") ? Colors.text : "#fff"}
                    />
                  </View>
                )
              }}
              labelStyle={{
                marginLeft: -20,
                fontFamily: "IranSans-Regular",
                fontSize: 12,
              }}
              onPress={() => {
                setActiveMenu(activeMenu.includes("Projects") ? "" : "Projects")
              }}
            />
          )}

          {activeMenu.includes("Projects") && (
            <View>
              <DrawerItem
                style={{ marginTop: -5 }}
                label={(props) => (
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: "#fff",
                      marginRight: 32,
                      textAlign: "right",
                    }}
                  >
                    پروژه های در گردش
                  </Text>
                )}
                icon={() => {
                  return (
                    <View
                      style={{
                        alignSelf: "center",
                        position: "absolute",
                        right: 50,
                        width: 10,
                        height: 10,
                        borderRadius: 5,
                        borderColor: "#fff",
                        borderWidth: 1,
                        backgroundColor: activeMenu == "Projects_recent" ? "#fff" : "transparent",
                      }}
                    ></View>
                  )
                }}
                labelStyle={{
                  marginLeft: -20,
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                }}
                onPress={() => {
                  setActiveMenu("Projects_recent")
                  props.navigation.navigate("Projects")
                }}
              />

              {(capabilities.includes("project_activate_all") ||
                capabilities.includes("project_activate_own_department")) && (
                <DrawerItem
                  style={{ marginTop: -10 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      پروژه های غیر فعال
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Projects_inactive" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Projects_inactive")
                    props.navigation.navigate("InactiveProjects")
                  }}
                />
              )}

              {capabilities.includes("projects_get_archived_projects_all_department") && (
                <DrawerItem
                  style={{ marginTop: -10 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      آرشیو پروژه ها
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Projects_archived" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Projects_archived")
                    props.navigation.navigate("ProjectsArchive")
                  }}
                />
              )}
            </View>
          )}

          {(capabilities.includes("transactions_see_menu_in_aio") ||
            capabilities.includes("invoice_create") ||
            capabilities.includes("invoice_getunpaid") ||
            capabilities.includes("transaction_add_income") ||
            capabilities.includes("transaction_add_outcome")) && (
            <DrawerItem
              style={{
                backgroundColor: activeMenu.includes("Sales") ? "#fff" : "transparent",
              }}
              label={(props) => (
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: activeMenu.includes("Sales") ? Colors.text : "#fff",
                    marginRight: 15,
                    textAlign: "right",
                  }}
                >
                  فروش و مالی
                </Text>
              )}
              icon={() => {
                return (
                  <View
                    style={{
                      alignSelf: "center",
                      position: "absolute",
                      right: 20,
                    }}
                  >
                    <Ionicons
                      name="cash-outline"
                      size={24}
                      color={activeMenu.includes("Sales") ? Colors.text : "#fff"}
                    />
                  </View>
                )
              }}
              labelStyle={{
                marginLeft: -20,
                fontFamily: "IranSans-Regular",
                fontSize: 12,
              }}
              onPress={() => {
                setActiveMenu(activeMenu.includes("Sales") ? "" : "Sales")
              }}
            />
          )}

          {activeMenu.includes("Sales") && (
            <View>
              {capabilities.includes("invoice_create") && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      ساخت صورتحساب
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Sales_makeinvoice" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Sales_makeinvoice")
                    props.navigation.navigate("Sales", {
                      screen: "MakeInvoice",
                    })
                  }}
                />
              )}
              {capabilities.includes("invoice_getunpaid") && (
                <DrawerItem
                  style={{ marginTop: -10 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      صورتحساب های رها
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Sales_unpaidinvoices" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Sales_unpaidinvoices")
                    props.navigation.navigate("Sales", {
                      screen: "UnpaidInvoices",
                    })
                  }}
                />
              )}

              {(capabilities.includes("transaction_add_income") ||
                capabilities.includes("transaction_add_outcome")) && (
                <DrawerItem
                  style={{ marginTop: -10 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      ثبت تراکنش
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Sales_addtransaction" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Sales_addtransaction")
                    props.navigation.navigate("Sales", {
                      screen: "AddTransaction",
                    })
                  }}
                />
              )}

              {capabilities.includes("transactions_get_pending") && (
                <DrawerItem
                  style={{ marginTop: -10 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      تایید تراکنش
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Sales_pendingtransactions" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Sales_pendingtransactions")
                    props.navigation.navigate("Sales", {
                      screen: "PendingTransactions",
                    })
                  }}
                />
              )}

              {capabilities.includes("transactions_see_menu_in_aio") && (
                <DrawerItem
                  style={{ marginTop: -10 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      تراکنش ها
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Sales_transactions" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Sales_transactions")
                    props.navigation.navigate("Sales", {
                      screen: "Transactions",
                    })
                  }}
                />
              )}
            </View>
          )}

          {(user_data.department == "sales" || user_data.department == "lead") &&
            (capabilities.includes("customer_get_list") ||
              capabilities.includes("customer_add") ||
              capabilities.includes("crm_get_own_leads")) && (
              <DrawerItem
                style={{
                  backgroundColor: activeMenu.includes("CRM") ? "#fff" : "transparent",
                }}
                label={(props) => (
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 14,
                      color: activeMenu.includes("CRM") ? Colors.text : "#fff",
                      marginRight: 15,
                      textAlign: "right",
                    }}
                  >
                    امور مشتریان
                  </Text>
                )}
                icon={() => {
                  return (
                    <View
                      style={{
                        alignSelf: "center",
                        position: "absolute",
                        right: 20,
                      }}
                    >
                      <Ionicons
                        name={"people-outline"}
                        size={24}
                        color={activeMenu.includes("CRM") ? Colors.text : "#fff"}
                      />
                    </View>
                  )
                }}
                labelStyle={{
                  marginLeft: -20,
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                }}
                onPress={() => {
                  setActiveMenu(activeMenu.includes("CRM") ? "" : "CRM")
                }}
              />
            )}

          {activeMenu.includes("CRM") && (
            <View>
              {capabilities.includes("crm_get_own_leads") &&
                capabilities.includes("role_domestic_jurisdiction") && (
                  <DrawerItem
                    style={{ marginTop: -5 }}
                    label={(props) => (
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color: "#fff",
                          marginRight: 32,
                          textAlign: "right",
                        }}
                      >
                        لیدهای داخلی در گردش
                      </Text>
                    )}
                    icon={() => {
                      return (
                        <View
                          style={{
                            alignSelf: "center",
                            position: "absolute",
                            right: 50,
                            width: 10,
                            height: 10,
                            borderRadius: 5,
                            borderColor: "#fff",
                            borderWidth: 1,
                            backgroundColor: activeMenu == "CRM_leads" ? "#fff" : "transparent",
                          }}
                        ></View>
                      )
                    }}
                    labelStyle={{
                      marginLeft: -20,
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                    }}
                    onPress={() => {
                      // setActiveMenu("CRM_leads")
                      // props.navigation.navigate("CRM", { screen: "Leads" })

                      Linking.openURL("https://panel.aminh.pro/crm/leads?region=ir")
                    }}
                  />
                )}

              {capabilities.includes("crm_get_own_leads") &&
                capabilities.includes("role_foreign_jurisdiction") && (
                  <DrawerItem
                    style={{ marginTop: -5 }}
                    label={(props) => (
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color: "#fff",
                          marginRight: 32,
                          textAlign: "right",
                        }}
                      >
                        لیدهای بین‌الملل در گردش
                      </Text>
                    )}
                    icon={() => {
                      return (
                        <View
                          style={{
                            alignSelf: "center",
                            position: "absolute",
                            right: 50,
                            width: 10,
                            height: 10,
                            borderRadius: 5,
                            borderColor: "#fff",
                            borderWidth: 1,
                            backgroundColor:
                              activeMenu == "CRM_international_leads" ? "#fff" : "transparent",
                          }}
                        ></View>
                      )
                    }}
                    labelStyle={{
                      marginLeft: -20,
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                    }}
                    onPress={() => {
                      // setActiveMenu("CRM_international_leads")
                      // props.navigation.navigate("CRM", { screen: "InternationalLeads" })

                      Linking.openURL("https://panel.aminh.pro/crm/leads?region=in")
                    }}
                  />
                )}

              {capabilities.includes("crm_add_lead") && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      افزودن لید
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor: activeMenu == "CRM_addlead" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    // setActiveMenu("CRM_addlead")
                    // props.navigation.navigate("CRM", { screen: "AddLead" })

                    Linking.openURL("https://panel.aminh.pro/crm/leads/add")
                  }}
                />
              )}

              {capabilities.includes("crm_get_own_leads") && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      یاداوری ها
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor: activeMenu == "CRM_reminders" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    // setActiveMenu("CRM_reminders")
                    // props.navigation.navigate("CRM", { screen: "Reminders" })

                    Linking.openURL("https://panel.aminh.pro/crm/reminders")
                  }}
                />
              )}

              {capabilities.includes("customer_get_list") && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      لیست مشتریان
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "CRM_customerslist" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    // setActiveMenu("CRM_customerslist")
                    // props.navigation.navigate("CRM", {
                    //   screen: "CustomersList",
                    // })

                    Linking.openURL("https://panel.aminh.pro/crm/customers")
                  }}
                />
              )}
            </View>
          )}

          {activeMenu.includes("CRM") && (
            <View>
              {capabilities.includes("customer_add") && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      افزودن مشتری
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "CRM_customer_add" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    // setActiveMenu("CRM_customer_add")
                    // props.navigation.navigate("CRM", { screen: "CustomerAdd" })

                    Linking.openURL("https://panel.aminh.pro/crm/customers/add")
                  }}
                />
              )}
            </View>
          )}

          {(capabilities.includes("support_ticket_get_own_responsible") ||
            capabilities.includes("lms_private_get_own_course") ||
            capabilities.includes("lms_private_get_own_department_course") ||
            capabilities.includes("lms_private_get_all_course") ||
            capabilities.includes("lms_public_get_own_department_course") ||
            capabilities.includes("lms_public_get_all_course") ||
            capabilities.includes("lms_add_course") ||
            capabilities.includes("lms_private_access_management_all_course") ||
            capabilities.includes("lms_private_access_management_own_department_course") ||
            capabilities.includes("lms_public_access_management_all_course") ||
            capabilities.includes("lms_public_access_management_own_department_course")) && (
            <DrawerItem
              style={{
                backgroundColor: activeMenu.includes("Support") ? "#fff" : "transparent",
              }}
              label={(props) => (
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: activeMenu.includes("Support") ? Colors.text : "#fff",
                    marginRight: 15,
                    textAlign: "right",
                  }}
                >
                  پشتیبانی
                </Text>
              )}
              icon={() => {
                return (
                  <View
                    style={{
                      alignSelf: "center",
                      position: "absolute",
                      right: 20,
                    }}
                  >
                    <Ionicons
                      name="help-buoy-outline"
                      size={24}
                      color={activeMenu.includes("Support") ? Colors.text : "#fff"}
                    />
                  </View>
                )
              }}
              labelStyle={{
                marginLeft: -20,
                fontFamily: "IranSans-Regular",
                fontSize: 12,
              }}
              onPress={() => {
                setActiveMenu(activeMenu.includes("Support") ? "" : "Support")
              }}
            />
          )}

          {activeMenu.includes("Support") &&
            (capabilities.includes("support_ticket_get_own_responsible") ||
              user_data.department == "lead") && (
              <View>
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      لیست تیکت ها
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Support_ticket_list" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Support_ticket_list")
                    props.navigation.navigate("Support", {
                      screen: "TicketList",
                    })
                  }}
                />
              </View>
            )}

          {activeMenu.includes("Support") &&
            capabilities.includes("lms_private_get_own_course") && (
              <View>
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      دوره های من
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Support_my_course_list" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Support_my_course_list")
                    props.navigation.navigate("Support", {
                      screen: "MyCoursesList",
                    })
                  }}
                />
              </View>
            )}

          {activeMenu.includes("Support") &&
            (capabilities.includes("lms_private_get_own_department_course") ||
              capabilities.includes("lms_private_get_all_course") ||
              capabilities.includes("lms_public_get_own_department_course") ||
              capabilities.includes("lms_public_get_all_course") ||
              user_data.department == "lead") && (
              <View>
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      لیست دوره ها
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Support_course_list" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Support_course_list")
                    props.navigation.navigate("Support", {
                      screen: "CoursesList",
                    })
                  }}
                />
              </View>
            )}

          {activeMenu.includes("Support") && capabilities.includes("lms_add_course") && (
            <View>
              <DrawerItem
                style={{ marginTop: -5 }}
                label={(props) => (
                  <Text
                    style={{
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                      color: "#fff",
                      marginRight: 32,
                      textAlign: "right",
                    }}
                  >
                    افزودن دوره
                  </Text>
                )}
                icon={() => {
                  return (
                    <View
                      style={{
                        alignSelf: "center",
                        position: "absolute",
                        right: 50,
                        width: 10,
                        height: 10,
                        borderRadius: 5,
                        borderColor: "#fff",
                        borderWidth: 1,
                        backgroundColor:
                          activeMenu == "Support_course_add" ? "#fff" : "transparent",
                      }}
                    ></View>
                  )
                }}
                labelStyle={{
                  marginLeft: -20,
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                }}
                onPress={() => {
                  setActiveMenu("Support_course_add")
                  props.navigation.navigate("Support", {
                    screen: "SetCourse",
                  })
                }}
              />
            </View>
          )}

          {activeMenu.includes("Support") &&
            (capabilities.includes("lms_private_access_management_all_course") ||
              capabilities.includes("lms_private_access_management_own_department_course")) && (
              <View>
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      دسترسی دوره پرسنل
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Support_staff_course_set_access"
                              ? "#fff"
                              : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Support_staff_course_set_access")
                    props.navigation.navigate("Support", {
                      screen: "SetStaffCourseAccess",
                    })
                  }}
                />
              </View>
            )}

          {activeMenu.includes("Support") &&
            (capabilities.includes("lms_public_access_management_all_course") ||
              capabilities.includes("lms_public_access_management_own_department_course")) && (
              <View>
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      دسترسی دوره مشتری
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Support_course_set_access" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Support_course_set_access")
                    props.navigation.navigate("Support", {
                      screen: "SetCourseAccess",
                    })
                  }}
                />
              </View>
            )}

          {(user_data.department == "site" || user_data.department == "lead") && (
            <DrawerItem
              style={{
                backgroundColor: activeMenu.includes("ASC") ? "#fff" : "transparent",
              }}
              label={(props) => (
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: activeMenu.includes("ASC") ? Colors.text : "#fff",
                    marginRight: 15,
                    textAlign: "right",
                  }}
                >
                  مرکز سایت
                </Text>
              )}
              icon={() => {
                return (
                  <View
                    style={{
                      alignSelf: "center",
                      position: "absolute",
                      right: 20,
                    }}
                  >
                    <Ionicons
                      name="shield-outline"
                      size={24}
                      color={activeMenu.includes("ASC") ? Colors.text : "#fff"}
                    />
                  </View>
                )
              }}
              labelStyle={{
                marginLeft: -20,
                fontFamily: "IranSans-Regular",
                fontSize: 12,
              }}
              onPress={() => {
                setActiveMenu(activeMenu.includes("ASC") ? "" : "ASC")
              }}
            />
          )}

          {activeMenu.includes("ASC") && (
            <View>
              {(user_data.department == "lead" || user_data.id == 2) && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      لیست سایت ها
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor: activeMenu == "ASC_site_list" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    // setActiveMenu("ASC_site_list")
                    // props.navigation.navigate("ASC", { screen: "SitesList" })

                    Linking.openURL("https://panel.aminh.pro/asc/websites")
                  }}
                />
              )}

              {capabilities.includes("asc_add_site") && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      افزودن سایت
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor: activeMenu == "ASC_add_site" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    // setActiveMenu("ASC_add_site")
                    // props.navigation.navigate("ASC", {
                    //   screen: "AddSite",
                    //   params: { site_id: false },
                    // })

                    Linking.openURL("https://panel.aminh.pro/asc/websites/add")
                  }}
                />
              )}
              {capabilities.includes("asc_market_setitem") && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      مارکت
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor: activeMenu == "ASC_MarketList" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    // setActiveMenu("ASC_MarketList")
                    // props.navigation.navigate("ASC", { screen: "MarketList" })

                    Linking.openURL("https://panel.aminh.pro/asc/market")
                  }}
                />
              )}

              {user_data.department == "site" &&
                (capabilities.includes("asc_get_sites_list") ||
                  capabilities.includes("asc_request_access") ||
                  capabilities.includes("asc_get_site_based_on_access") ||
                  capabilities.includes("asc_get_sites_all")) && (
                  <DrawerItem
                    style={{ marginTop: -5 }}
                    label={(props) => (
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color: "#fff",
                          marginRight: 32,
                          textAlign: "right",
                        }}
                      >
                        مدیریت دسترسی ها
                      </Text>
                    )}
                    icon={() => {
                      return (
                        <View
                          style={{
                            alignSelf: "center",
                            position: "absolute",
                            right: 50,
                            width: 10,
                            height: 10,
                            borderRadius: 5,
                            borderColor: "#fff",
                            borderWidth: 1,
                            backgroundColor:
                              activeMenu == "ASC_user_request_access" ? "#fff" : "transparent",
                          }}
                        ></View>
                      )
                    }}
                    labelStyle={{
                      marginLeft: -20,
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                    }}
                    onPress={() => {
                      // setActiveMenu("ASC_user_request_access")
                      // props.navigation.navigate("ASC", {
                      //   screen: "UserRequestAccess",
                      // })

                      Linking.openURL("https://panel.aminh.pro/asc/websites")
                    }}
                  />
                )}
            </View>
          )}

          {capabilities.includes("website_see_menu_in_aio") && (
            <DrawerItem
              style={{
                backgroundColor: activeMenu.includes("Website") ? "#fff" : "transparent",
              }}
              label={(props) => (
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: activeMenu.includes("Website") ? Colors.text : "#fff",
                    marginRight: 15,
                    textAlign: "right",
                  }}
                >
                  وب سایت
                </Text>
              )}
              icon={() => {
                return (
                  <View
                    style={{
                      alignSelf: "center",
                      position: "absolute",
                      right: 20,
                    }}
                  >
                    <Ionicons
                      name="globe-outline"
                      size={24}
                      color={activeMenu.includes("Website") ? Colors.text : "#fff"}
                    />
                  </View>
                )
              }}
              labelStyle={{
                marginLeft: -20,
                fontFamily: "IranSans-Regular",
                fontSize: 12,
              }}
              onPress={() => {
                setActiveMenu(activeMenu.includes("Website") ? "" : "Website")
              }}
            />
          )}

          {activeMenu.includes("Website") && (
            <View>
              {capabilities.includes("website_get_media_list_all") && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      مدیریت فایل ها
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor: activeMenu == "Website_media" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Website_media")
                    props.navigation.navigate("Website", { screen: "Media" })
                  }}
                />
              )}
              {(capabilities.includes("website_get_blog_posts_list_all") ||
                capabilities.includes("website_get_blog_posts_list_own_sent")) && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      لیست مطالب بلاگ
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Website_blog_posts_list" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Website_blog_posts_list")
                    props.navigation.navigate("Website", {
                      screen: "PostsList",
                    })
                  }}
                />
              )}

              {capabilities.includes("website_add_blog_post") && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      افزودن مطلب بلاگ
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Website_add_blog_post" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Website_add_blog_post")
                    props.navigation.navigate("Website", { screen: "AddPost" })
                  }}
                />
              )}

              {(capabilities.includes("website_add_blog_category") ||
                capabilities.includes("website_edit_blog_category") ||
                capabilities.includes("website_remove_blog_category")) && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      دسته بندی های بلاگ
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Website_blog_category" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Website_blog_category")
                    props.navigation.navigate("Website", {
                      screen: "Categories",
                    })
                  }}
                />
              )}
              {capabilities.includes("website_get_portfolio_posts_list_all") && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      لیست نمونه کارها
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Website_portfolio_posts_list" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Website_portfolio_posts_list")
                    props.navigation.navigate("Website", {
                      screen: "PortfolioList",
                    })
                  }}
                />
              )}

              {capabilities.includes("website_see_portfolio_en_menu") && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      لیست نمونه کارهای انگلیسی
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Website_portfolio_posts_list_en"
                              ? "#fff"
                              : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Website_portfolio_posts_list_en")
                    props.navigation.navigate("Website", {
                      screen: "PortfolioListEn",
                    })
                  }}
                />
              )}
              {capabilities.includes("website_add_portfolio_post") && (
                <DrawerItem
                  style={{ marginTop: -5 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      افزودن نمونه کار
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Website_add_portfolio_post" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Website_add_portfolio_post")
                    props.navigation.navigate("Website", {
                      screen: "AddPortfolio",
                    })
                  }}
                />
              )}
            </View>
          )}

          {(user_data.department == "lead" ||
            capabilities.includes("staffs_get_own_department")) && (
            <DrawerItem
              style={{
                backgroundColor: activeMenu.includes("Settings") ? "#fff" : "transparent",
              }}
              label={(props) => (
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 14,
                    color: activeMenu.includes("Settings") ? Colors.text : "#fff",
                    marginRight: 15,
                    textAlign: "right",
                  }}
                >
                  مدیریت
                </Text>
              )}
              icon={() => {
                return (
                  <View
                    style={{
                      alignSelf: "center",
                      position: "absolute",
                      right: 20,
                    }}
                  >
                    <Ionicons
                      name={"settings-outline"}
                      size={24}
                      color={activeMenu.includes("Settings") ? Colors.text : "#fff"}
                    />
                  </View>
                )
              }}
              labelStyle={{
                marginLeft: -20,
                fontFamily: "IranSans-Regular",
                fontSize: 12,
              }}
              onPress={() => {
                setActiveMenu(activeMenu.includes("Settings") ? "" : "Settings")
              }}
            />
          )}

          {activeMenu.includes("Settings") && (
            <View>
              {capabilities.includes("services_add") &&
                capabilities.includes("services_get_all") && (
                  <DrawerItem
                    style={{ marginTop: -5 }}
                    label={(props) => (
                      <Text
                        style={{
                          fontFamily: "IranSans-Regular",
                          fontSize: 12,
                          color: "#fff",
                          marginRight: 32,
                          textAlign: "right",
                        }}
                      >
                        خدمات
                      </Text>
                    )}
                    icon={() => {
                      return (
                        <View
                          style={{
                            alignSelf: "center",
                            position: "absolute",
                            right: 50,
                            width: 10,
                            height: 10,
                            borderRadius: 5,
                            borderColor: "#fff",
                            borderWidth: 1,
                            backgroundColor:
                              activeMenu == "Settings_services" ? "#fff" : "transparent",
                          }}
                        ></View>
                      )
                    }}
                    labelStyle={{
                      marginLeft: -20,
                      fontFamily: "IranSans-Regular",
                      fontSize: 12,
                    }}
                    onPress={() => {
                      setActiveMenu("Settings_services")
                      props.navigation.navigate("Settings", {
                        screen: "Services",
                      })
                    }}
                  />
                )}

              {capabilities.includes("capabilities_management") && (
                <DrawerItem
                  style={{ marginTop: -10 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      دسترسی ها
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Settings_capability" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Settings_capability")
                    props.navigation.navigate("Settings", {
                      screen: "Capability",
                    })
                  }}
                />
              )}

              {(user_data.department == "lead" ||
                capabilities.includes("staffs_get_own_department")) && (
                <DrawerItem
                  style={{ marginTop: -10 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      مدیریت پرسنل
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Settings_usersmanagement" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Settings_usersmanagement")
                    props.navigation.navigate("Settings", {
                      screen: "UsersManagement",
                    })
                  }}
                />
              )}

              {user_data.department == "lead" && (
                <DrawerItem
                  style={{ marginTop: -10 }}
                  label={(props) => (
                    <Text
                      style={{
                        fontFamily: "IranSans-Regular",
                        fontSize: 12,
                        color: "#fff",
                        marginRight: 32,
                        textAlign: "right",
                      }}
                    >
                      گزارشات
                    </Text>
                  )}
                  icon={() => {
                    return (
                      <View
                        style={{
                          alignSelf: "center",
                          position: "absolute",
                          right: 50,
                          width: 10,
                          height: 10,
                          borderRadius: 5,
                          borderColor: "#fff",
                          borderWidth: 1,
                          backgroundColor:
                            activeMenu == "Settings_companyreports" ? "#fff" : "transparent",
                        }}
                      ></View>
                    )
                  }}
                  labelStyle={{
                    marginLeft: -20,
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                  }}
                  onPress={() => {
                    setActiveMenu("Settings_companyreports")
                    props.navigation.navigate("Settings", {
                      screen: "CompanyReports",
                    })
                  }}
                />
              )}
            </View>
          )}

          <DrawerItem
            label={(props) => (
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 14,
                  color: props.color,
                  marginRight: 15,
                  textAlign: "right",
                }}
              >
                {"خروج از حساب"}
              </Text>
            )}
            icon={() => {
              return (
                <View
                  style={{
                    alignSelf: "center",
                    position: "absolute",
                    right: 20,
                  }}
                >
                  <Ionicons name={"log-out-outline"} size={24} color="#fff" />
                </View>
              )
            }}
            activeTintColor="#fff"
            inactiveTintColor="#fff"
            labelStyle={{
              marginLeft: -20,
              fontFamily: "IranSans-Regular",
              fontSize: 12,
            }}
            onPress={() => {
              if (confirm("آیا از خروج اطمینان دارید؟")) {
                logOut()
              }
            }}
          />

          <DrawerItem
            style={{
              backgroundColor: activeMenu.includes("Bugs") ? "#fff" : "rgba(255, 112, 67,0.4)",
            }}
            label={(props) => (
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 14,
                  color: activeMenu.includes("Bugs") ? Colors.text : "#fff",
                  marginRight: 15,
                  textAlign: "right",
                }}
              >
                گزارش باگ
              </Text>
            )}
            icon={() => {
              return (
                <View
                  style={{
                    alignSelf: "center",
                    position: "absolute",
                    right: 20,
                  }}
                >
                  <Ionicons
                    name="bug-outline"
                    size={24}
                    color={activeMenu.includes("Bugs") ? Colors.text : "#fff"}
                  />
                </View>
              )
            }}
            labelStyle={{
              marginLeft: -20,
              fontFamily: "IranSans-Regular",
              fontSize: 12,
            }}
            onPress={() => {
              setActiveMenu(activeMenu.includes("Bugs") ? "" : "Bugs")
              props.navigation.navigate("Bugs")
            }}
          />
        </View>
      </ScrollView>
      <View
        style={{
          width: "100%",
          height: 55,
          position: "absolute",
          bottom: 0,
          borderTopColor: "rgba(255,255,255,0.3)",
          borderTopWidth: 1,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          backgroundColor: Colors.text,
        }}
      >
        <Image
          style={{ width: 36, height: 36, borderRadius: 18 }}
          source={require("../assets/img/logoR-500.png")}
        />
        <View style={{ marginLeft: 5 }}>
          <Text style={{ fontSize: 12, color: "#fff" }}>AminH Company</Text>
          <Text style={{ fontSize: 10, color: "rgba(255,255,255,0.35)" }}>Since 2009</Text>
        </View>
      </View>
    </View>
  )
}

const DrawerDrawerNavigator = createDrawerNavigator()

const DrawerNavigator = () => {
  const capabilities = useSelector((store) => store.user.capabilities)
  const user_data = useSelector((store) => store.user.user_data)

  return (
    <DrawerDrawerNavigator.Navigator
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      screenOptions={{
        drawerActiveTintColor: "#fff",
        drawerInactiveTintColor: "#fff",
        drawerActiveBackgroundColor: "#d9d9d9",
        labelStyle: {
          marginLeft: -20,
          fontFamily: "IranSans-Regular",
          fontSize: 14,
          color: "#fff",
        },
        drawerStyle: { width: 240 },
      }}
    >
      {user_data.department != "seo" && capabilities.includes("project_get_own") && (
        <DrawerDrawerNavigator.Screen
          name="Projects"
          component={ProjectsScreen}
          options={{
            ...ProjectsScreenOptions,
            drawerType: DESKTOP ? "permanent" : "slide",
            title: "پروژه ها",
          }}
        />
      )}
      {(user_data.department == "lead" || capabilities.includes("eam_set_own_record")) && (
        <DrawerDrawerNavigator.Screen
          name="EAM"
          component={EAMNavigator}
          options={{
            headerShown: false,
            drawerPosition: "right",
            drawerType: DESKTOP ? "permanent" : "slide",
          }}
        />
      )}

      {((user_data.department != "site" &&
        user_data.department != "graphic" &&
        user_data.department != "seo") ||
        capabilities.includes("transactions_see_menu_in_aio")) && (
        <DrawerDrawerNavigator.Screen
          name="Sales"
          component={SalesNavigator}
          options={{
            headerShown: false,
            drawerPosition: "right",
            drawerType: DESKTOP ? "permanent" : "slide",
          }}
        />
      )}

      {(user_data.department == "sales" || user_data.department == "lead") && (
        <DrawerDrawerNavigator.Screen
          name="CRM"
          component={CRMNavigator}
          options={{
            headerShown: false,
            drawerPosition: "right",
            drawerType: DESKTOP ? "permanent" : "slide",
          }}
        />
      )}

      {/*//? Access to Support menu in aio conditaion */}
      {(capabilities.includes("support_ticket_get_own_responsible") ||
        capabilities.includes("lms_private_get_own_course") ||
        capabilities.includes("lms_private_get_own_department_course") ||
        capabilities.includes("lms_private_get_all_course") ||
        capabilities.includes("lms_public_get_own_department_course") ||
        capabilities.includes("lms_public_get_all_course") ||
        capabilities.includes("lms_add_course") ||
        capabilities.includes("lms_private_access_management_all_course") ||
        capabilities.includes("lms_private_access_management_own_department_course") ||
        capabilities.includes("lms_public_access_management_all_course") ||
        capabilities.includes("lms_public_access_management_own_department_course")) && (
        <DrawerDrawerNavigator.Screen
          name="Support"
          component={SupportNavigator}
          options={{
            headerShown: false,
            drawerPosition: "right",
            drawerType: DESKTOP ? "permanent" : "slide",
          }}
        />
      )}

      {(user_data.department == "site" || user_data.department == "lead") && (
        <DrawerDrawerNavigator.Screen
          name="ASC"
          component={ASCNavigator}
          options={{
            headerShown: false,
            drawerPosition: "right",
            drawerType: DESKTOP ? "permanent" : "slide",
          }}
        />
      )}

      {capabilities.includes("website_see_menu_in_aio") && (
        <DrawerDrawerNavigator.Screen
          name="Website"
          component={WebsiteNavigator}
          options={{
            headerShown: false,
            drawerPosition: "right",
            drawerType: DESKTOP ? "permanent" : "slide",
          }}
        />
      )}

      {capabilities.includes("projects_get_archived_projects_all_department") && (
        <DrawerDrawerNavigator.Screen
          name="ProjectsArchive"
          component={ProjectsArchiveScreen}
          options={{
            ...ProjectsArchiveScreenOptions,
            drawerType: DESKTOP ? "permanent" : "slide",
            title: "آرشیو پروژه ها",
          }}
        />
      )}
      {(capabilities.includes("project_activate_all") ||
        capabilities.includes("project_activate_own_department")) && (
        <DrawerDrawerNavigator.Screen
          name="InactiveProjects"
          component={InactiveProjectsScreen}
          options={{
            ...InactiveProjectsScreenOptions,
            drawerType: DESKTOP ? "permanent" : "slide",
            title: "پروژه های غیرفعال",
          }}
        />
      )}

      <DrawerDrawerNavigator.Screen
        name="Settings"
        component={SettingsNavigator}
        options={{
          headerShown: false,
          drawerPosition: "right",
          drawerType: "slide",
        }}
      />

      <DrawerDrawerNavigator.Screen
        name="Bugs"
        component={BugsScreen}
        options={{
          ...BugsScreenOptions,
          drawerType: DESKTOP ? "permanent" : "slide",
          title: "باگ ها",
        }}
      />
    </DrawerDrawerNavigator.Navigator>
  )
}

export default DrawerNavigator
