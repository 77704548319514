import { useReducer, useEffect } from "react"
import {
  View,
  Text,
  Dimensions,
  ScrollView,
  Image,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import { useSelector } from "react-redux"
import axios from "axios"
import Config from "../../constants/Config"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false
import { logOut } from "../../components/Functions"
import StatBox from "../../components/StatBox"
import StatBoxChartDoughnut from "../../components/StatBoxChartDoughnut"
import StatBoxChartLine from "../../components/StatBoxChartLine"
import { separate } from "../../components/Functions"
import moment from "moment-jalaali"
import { Ionicons } from "@expo/vector-icons"
import ProgressBar from "../../components/ProgressBar"
import InputNote from "../../components/InputNote"

const SET_LOADING = "SET_LOADING"
const SET_DATA = "SET_DATA"
const SET_ACTIVE_PERIOD = "SET_ACTIVE_PERIOD"
const SET_CONFIRMED = "SET_CONFIRMED"

const formReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_DATA:
      let analysis_data = action.data.analysis
      let income_profit_variation_data = {
        labels: [],
        datasets: [
          {
            label: "درآمد",
            data: [],
            borderColor: Colors.green,
            backgroundColor: Colors.green,
          },
        ],
      }

      let indicator_profitrate_variation_data = {
        labels: [],
        datasets: [
          {
            label: "شاخص",
            data: [],
            borderColor: Colors.blue,
            backgroundColor: Colors.blue,
          },
        ],
      }

      let eam_variation_data = {
        labels: [],
        datasets: [
          {
            label: "جریمه",
            data: [],
            borderColor: Colors.primary,
            backgroundColor: Colors.primary,
          },
          {
            label: "تاخیر",
            data: [],
            borderColor: Colors.error,
            backgroundColor: Colors.error,
          },
        ],
      }

      for (let i = analysis_data.length - 1; i >= 0; i--) {
        income_profit_variation_data.labels.push(analysis_data[i].date)
        income_profit_variation_data.datasets[0].data.push(analysis_data[i].user_income.impure)

        indicator_profitrate_variation_data.labels.push(analysis_data[i].date)
        indicator_profitrate_variation_data.datasets[0].data.push(
          analysis_data[i].points.projects_indicator2
        )

        eam_variation_data.labels.push(analysis_data[i].date)
        eam_variation_data.datasets[0].data.push(analysis_data[i].eam_data.number_of_penalty)
        eam_variation_data.datasets[1].data.push(analysis_data[i].eam_data.number_of_late)
      }
      return {
        ...state,
        values: {
          ...state.values,
          data: action.data,
          income_profit_variation_data: income_profit_variation_data,
          indicator_profitrate_variation_data: indicator_profitrate_variation_data,
          eam_variation_data: eam_variation_data,
        },
      }
    case SET_ACTIVE_PERIOD:
      return {
        ...state,
        values: {
          ...state.values,
          active_period: action.value,
        },
      }

    case SET_CONFIRMED:
      return {
        ...state,
        values: {
          ...state.values,
          confirmed: action.value,
        },
      }

    default:
      return state
  }
}

const UserProfileScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      data: null,
      active_period: "this_month",
      income_profit_variation_data: null,
      indicator_profitrate_variation_data: null,
      eam_variation_data: null,
      confirmed: false,
    },
    validities: {},
  })

  const get_data = async (period = "this_month") => {
    const url = Config.aioUrl + "/pes/analyse?type=self"
    let reqHedear = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    }

    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_DATA, data: response.data.data })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
        console.log(error)
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      const isAccepted = confirm(
        "اطلاعات این صفحه محرمانه می باشد. اگر تایید می کنید که فقط خود شما در حال مشاهده این صفحه هستید روی گزینه اوکی کلیک کنید."
      )

      if (isAccepted) {
        dispatchFormState({ type: SET_CONFIRMED, value: true })
        get_data()
      } else {
        // Go back
        props.navigation.goBack()
      }
    })

    return focused
  }, [props.navigation])

  return (
    <View
      style={{
        height: "100%",
        overflow: "hidden",
        backgroundColor: Colors.bg,
        paddingHorizontal: 25,
      }}
    >
      <Header title="پروفایل" noToggle noNotif noActiveSessions />
      {formState.values.confirmed && (
        <View style={{ flex: 1, height: "100%", justifyContent: "center", alignItems: "center" }}>
          {!formState.values.loading && formState.values.data && (
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{ marginTop: 20, width: "100%" }}
            >
              <InputNote
                title="اعلان مهم"
                content="عطف به قرارداد پرسنل، بند حفظ محرمانگی داده ها، اطلاعات این صفحه محرمانه می باشد. فقط شما مجاز به رويت اطلاعات می باشید. این محرمانگی شامل درون و برون سازمان می شود."
                backgroundColor={Colors.primary}
                textColor={"#fff"}
              />
              <View
                style={{ flex: 1, height: "100%", width: "100%", flexDirection: "row-reverse" }}
              >
                <View
                  style={{
                    flex: 0.5,
                    height: 310,
                    backgroundColor: Colors.secondary,
                    borderRadius: 15,
                    padding: 20,
                    marginBottom: 10,
                    marginLeft: 10,
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      height: 85,
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: 15,
                    }}
                  >
                    <Image
                      source={{ uri: formState.values.data.user_data.avatar }}
                      style={{ width: 85, height: 85, borderRadius: 42.5 }}
                    />
                  </View>

                  <View style={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
                    <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: "#fff" }}>
                      {formState.values.data.user_data.name}
                    </Text>
                  </View>

                  {formState.values.data.user_data.employment_date && (
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row-reverse",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: 20,
                      }}
                    >
                      <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: "#fff" }}>
                        سابقه
                      </Text>
                      <Text style={{ fontFamily: "IranSans-Medium", fontSize: 14, color: "#fff" }}>
                        {moment.unix(formState.values.data.user_data.employment_date).toNow(true)}
                      </Text>
                    </View>
                  )}
                </View>
                <View style={{ flex: 3, flexDirection: "row-reverse", columnGap: 10 }}>
                  <View style={{ flex: 2 }}>
                    <View
                      style={{
                        flex: 1,
                        width: "100%",
                        flexDirection: "row-reverse",
                        columnGap: 10,
                      }}
                    >
                      <StatBox
                        title="درآمد ماه قبل"
                        iconname="journal-outline"
                        value={separate(formState.values.data.analysis[1].user_income.pure)}
                        secondValue={separate(formState.values.data.analysis[1].user_income.impure)}
                        period={"نسبت به ماه گذشته"}
                      />
                      {formState.values.data.analysis[0].points.projects_indicator2 != 0 && (
                        <StatBox
                          title="شاخص این ماه"
                          iconname="md-file-tray-full"
                          value={
                            formState.values.data.user_data.id == 5
                              ? separate(
                                  formState.values.data.analysis[0].points.projects_indicator2
                                )
                              : formState.values.data.analysis[0].points.projects_indicator2
                          }
                          change={
                            (
                              (formState.values.data.analysis[0].points.projects_indicator2 -
                                formState.values.data.analysis[1].points.projects_indicator2) /
                              formState.values.data.analysis[1].points.projects_indicator2
                            ).toFixed(2) * 100
                          }
                          period={"نسبت به ماه گذشته"}
                        />
                      )}
                    </View>
                    <View
                      style={{
                        flex: 1,
                        width: "100%",
                        flexDirection: "row-reverse",
                        columnGap: 10,
                      }}
                    >
                      {formState.values.data.user_data.indicator != 0 && (
                        <View
                          style={{
                            flex: 1,
                            height: 150,
                            backgroundColor: "#F2F2F2",
                            borderRadius: 15,
                            padding: 20,
                            marginBottom: 10,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row-reverse",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "IranSans-Regular",
                                fontSize: 16,
                                color: Colors.text,
                              }}
                            >
                              میزان پیشرفت
                            </Text>
                            <Ionicons
                              name="medal-outline"
                              color={props.iconcolor ? props.iconcolor : Colors.secondary}
                              size={30}
                            />
                          </View>
                          <View
                            style={{
                              flexDirection: "row-reverse",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              marginTop: 10,
                            }}
                          >
                            <ProgressBar
                              start={"0"}
                              end={formState.values.data.user_data.indicator}
                              progress={
                                (
                                  formState.values.data.analysis[0].points.projects_indicator2 /
                                  formState.values.data.user_data.indicator
                                ).toFixed(2) * 100
                              }
                            />
                          </View>
                        </View>
                      )}
                      {formState.values.data.analysis[1].points.projects_indicator2 != 0 && (
                        <StatBox
                          title="شاخص ماه قبل"
                          iconname="md-file-tray-full"
                          value={
                            formState.values.data.user_data.id == 5
                              ? separate(
                                  formState.values.data.analysis[1].points.projects_indicator2
                                )
                              : formState.values.data.analysis[1].points.projects_indicator2
                          }
                          change={
                            (
                              (formState.values.data.analysis[1].points.projects_indicator2 -
                                formState.values.data.analysis[2].points.projects_indicator2) /
                              formState.values.data.analysis[2].points.projects_indicator2
                            ).toFixed(2) * 100
                          }
                          period={"نسبت به ماه گذشته"}
                        />
                      )}
                    </View>
                  </View>

                  <View style={{ flex: 1 }}>
                    <StatBoxChartDoughnut
                      title="وضعیت تردد"
                      iconname="time-outline"
                      value={formState.values.data.analysis[0].eam_data.WorktimeInMonth / 8}
                      period={"روز کاری در این ماه"}
                      data={
                        formState.values.data.analysis[0].eam_data.Worked != 0
                          ? {
                              labels: ["به موقع", "تاخیر", "جریمه", "ثبت نشده"],
                              datasets: [
                                {
                                  data: [
                                    formState.values.data.analysis[0].eam_data.number_of_worked -
                                      (formState.values.data.analysis[0].eam_data.number_of_late +
                                        formState.values.data.analysis[0].eam_data
                                          .number_of_penalty),
                                    formState.values.data.analysis[0].eam_data.number_of_late,
                                    formState.values.data.analysis[0].eam_data.number_of_penalty,
                                    formState.values.data.analysis[0].eam_data.WorktimeInMonth / 8 -
                                      formState.values.data.analysis[0].eam_data.number_of_worked,
                                  ],
                                  backgroundColor: [
                                    Colors.green,
                                    Colors.error,
                                    Colors.primary,
                                    Colors.secondText,
                                  ],
                                  borderColor: ["#F2F2F2", "#F2F2F2", "#F2F2F2", "#F2F2F2"],
                                  borderWidth: 4,
                                },
                              ],
                            }
                          : false
                      }
                    />
                  </View>
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: "row-reverse", columnGap: 10 }}>
                <View style={{ flex: 1 }}>
                  <StatBoxChartLine
                    title="درآمد در طول زمان"
                    iconname="trending-up"
                    value={" "}
                    data={
                      formState.values.income_profit_variation_data != null
                        ? formState.values.income_profit_variation_data
                        : false
                    }
                  />
                </View>

                <View style={{ flex: 1 }}>
                  {formState.values.data.analysis[0].points.projects_indicator2 != 0 && (
                    <StatBoxChartLine
                      title="شاخص در طول زمان"
                      iconname="trending-up"
                      value={" "}
                      data={
                        formState.values.indicator_profitrate_variation_data != null
                          ? formState.values.indicator_profitrate_variation_data
                          : false
                      }
                    />
                  )}
                </View>
              </View>
            </ScrollView>
          )}
          {formState.values.loading && <ActivityIndicator size="large" color={Colors.primary} />}
        </View>
      )}
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerType: "permanent",
}

export default UserProfileScreen
