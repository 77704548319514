import { useReducer, useCallback, useEffect } from "react"
import { View, Text, ScrollView, Dimensions, TouchableOpacity } from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import Config from "../../constants/Config"
import { Ionicons } from "@expo/vector-icons"
import InputNote from "../../components/InputNote"
import { logOut } from "../../components/Functions"
import axios from "axios"
import { Placeholder, PlaceholderLine, ShineOverlay } from "rn-placeholder"
import Input from "../../components/Input"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_COURSES = "SET_COURSES"

const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER"
const SET_DEPARTMENTS = "SET_DEPARTMENTS"

const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }

    case SET_ACTIVE_FILTER:
      return {
        ...state,
        values: {
          ...state.values,
          filters: {
            ...state.values.filters,
            [action.id]: action.value,
          },
        },
      }

    case SET_DEPARTMENTS:
      return {
        ...state,
        values: {
          ...state.values,
          departments: action.data,
        },
      }

    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_COURSES:
      return {
        ...state,
        values: {
          ...state.values,
          courses: action.data,
        },
      }
    default:
      return state
  }
}

const LoadingPlaceHolder = (props) => {
  return (
    <View>
      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
      </Placeholder>
    </View>
  )
}

const CoursesListScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const role = useSelector((store) => store.user.user_data.role)

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      courses: [],

      filters: {
        department: "all",
        type: "all",
      },
      search: "",
    },
  })

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const filtersDataExtractor = (courses) => {
    const departments = courses
      .map((item) => ({
        id: item.department,
        name: item.department
          ?.replace("site", "سایت")
          ?.replace("sales", "فروش")
          ?.replace("graphic", "گرافیک")
          ?.replace("hr", "منابع انسانی"),
      }))
      .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)

    console.log(departments)

    dispatchFormState({ type: SET_DEPARTMENTS, data: departments })
  }

  const get_courses = async () => {
    let url = null

    dispatchFormState({ type: SET_LOADING, loading: true })

    if (role === "customer") {
      url = Config.aioUrl + "/customer/lms/course/list"
    } else {
      url = Config.aioUrl + "/employee/lms/course/list"
    }

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
        dispatchFormState({ type: SET_COURSES, data: response.data.data })

        filtersDataExtractor(response.data.data)
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_courses()
    })

    return focused
  }, [props.navigation])

  const filterHandler = (course) => {
    const department =
      course.department === formState.values.filters.department ||
      formState.values.filters.department == "all"
    const type =
      (formState.values.filters.type === "employee" && course.private) ||
      (formState.values.filters.type === "user" && !course.private) ||
      formState.values.filters.type == "all"
    const search =
      course.title?.toLowerCase().includes(formState.values.search?.toLowerCase()) ||
      formState.values.search == ""

    return department && type && search
  }

  const courses = formState.values.courses.filter(filterHandler)

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title="دوره های من" noNotif noActiveSessions noToggle />

      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          paddingHorizontal: DESKTOP ? "30%" : 25,
          marginBottom: 10,
        }}
      >
        <View
          style={{
            flexDirection: "row-reverse",
            alignItems: "center",
            height: 40,
            width: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-Medium",
              fontSize: 12,
              color: Colors.text,
            }}
          >
            دسته نمایش:{" "}
          </Text>

          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{
              minWidth: "100%",
              flexDirection: "row-reverse",
            }}
            horizontal
            showsHorizontalScrollIndicator={false}
          >
            <TouchableOpacity
              style={{
                paddingVertical: 5,
                paddingHorizontal: 10,
                borderRadius: 10,
                backgroundColor: formState.values.filters.type == "all" ? Colors.blue : "#F2F2F2",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
              onPress={() => {
                dispatchFormState({
                  type: SET_ACTIVE_FILTER,
                  id: "type",
                  value: "all",
                })
              }}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color: formState.values.filters.type == "all" ? "#fff" : Colors.text,
                }}
              >
                همه
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                paddingVertical: 5,
                paddingHorizontal: 10,
                borderRadius: 10,
                backgroundColor: formState.values.filters.type == "user" ? Colors.blue : "#F2F2F2",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
              onPress={() => {
                dispatchFormState({
                  type: SET_ACTIVE_FILTER,
                  id: "type",
                  value: "user",
                })
              }}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color: formState.values.filters.type == "user" ? "#fff" : Colors.text,
                }}
              >
                دوره مشتری
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                paddingVertical: 5,
                paddingHorizontal: 10,
                borderRadius: 10,
                backgroundColor:
                  formState.values.filters.type == "employee" ? Colors.blue : "#F2F2F2",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
              onPress={() => {
                dispatchFormState({
                  type: SET_ACTIVE_FILTER,
                  id: "type",
                  value: "employee",
                })
              }}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color: formState.values.filters.type == "employee" ? "#fff" : Colors.text,
                }}
              >
                دوره پرسنل
              </Text>
            </TouchableOpacity>
          </ScrollView>
        </View>

        <View
          style={{
            flexDirection: "row-reverse",
            alignItems: "center",
            height: 40,
            width: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "IranSans-Medium",
              fontSize: 12,
              color: Colors.text,
            }}
          >
            دپارتمان:
          </Text>

          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{ flexDirection: "row-reverse", minWidth: "100%" }}
            horizontal
            showsHorizontalScrollIndicator={false}
          >
            <TouchableOpacity
              style={{
                paddingVertical: 5,
                paddingHorizontal: 10,
                borderRadius: 10,
                backgroundColor:
                  formState.values.filters.department == "all" ? Colors.blue : "#F2F2F2",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
              onPress={() => {
                dispatchFormState({
                  type: SET_ACTIVE_FILTER,
                  id: "department",
                  value: "all",
                })
              }}
            >
              <Text
                style={{
                  fontFamily: "IranSans-Regular",
                  fontSize: 12,
                  color: formState.values.filters.department == "all" ? "#fff" : Colors.text,
                }}
              >
                همه
              </Text>
            </TouchableOpacity>

            {formState.values.departments?.map((item) => (
              <TouchableOpacity
                style={{
                  paddingVertical: 5,
                  paddingHorizontal: 10,
                  borderRadius: 10,
                  backgroundColor:
                    formState.values.filters.department == item.id ? Colors.blue : "#F2F2F2",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 5,
                }}
                onPress={() => {
                  dispatchFormState({
                    type: SET_ACTIVE_FILTER,
                    id: "department",
                    value: item.id,
                  })
                }}
              >
                <Text
                  style={{
                    fontFamily: "IranSans-Regular",
                    fontSize: 12,
                    color: formState.values.filters.department == item.id ? "#fff" : Colors.text,
                  }}
                >
                  {item.name}
                </Text>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </View>

        <View
          style={{
            flex: 1,
            flexDirection: "row-reverse",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 10,
            gap: 10,
            width: "100%",
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <Input
              label="به دنبال چه می گردی؟"
              id="search"
              onChange={inputChangeHandler}
              validity
            />
          </View>
        </View>
      </View>

      <View
        style={{
          paddingHorizontal: DESKTOP ? "30%" : 25,
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <View style={{ flex: 1, paddingBottom: 25 }}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ paddingBottom: 50, maxHeight: "55vh" }}
          >
            {formState.values.loading ? (
              <>
                <LoadingPlaceHolder />
              </>
            ) : (
              <>
                {courses.map((item) => (
                  <TouchableOpacity
                    key={item.id}
                    style={{
                      width: "100%",
                      backgroundColor: "#F2F2F2",
                      borderRadius: 15,
                      padding: 20,
                      marginBottom: 10,
                      flexDirection: "row-reverse",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      props.navigation.navigate("Course", {
                        course_id: item.id,
                      })
                    }}
                  >
                    <Ionicons name="school" color={Colors.text} size={24} />
                    <Text
                      style={{
                        fontFamily: "IranSans-Medium",
                        fontSize: 14,
                        color: Colors.text,
                        marginRight: 5,
                      }}
                    >
                      {item.title}
                    </Text>
                  </TouchableOpacity>
                ))}

                {courses.length == 0 && (
                  <InputNote
                    content={
                      formState.values.filters.department == "all" &&
                      formState.values.filters.type == "all" &&
                      formState.values.search == ""
                        ? "شما هیچ دوره آموزشی فعالی ندارید"
                        : "شما هیچ دوره آموزشی فعالی با این فیلتر ندارید"
                    }
                  />
                )}
              </>
            )}
          </ScrollView>
        </View>
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default CoursesListScreen
