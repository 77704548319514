import { useReducer, useCallback, useEffect } from "react"
import { View, Text, ScrollView, Dimensions, TouchableOpacity } from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import Config from "../../constants/Config"
import InputNote from "../../components/InputNote"
import Btn from "../../components/Btn"
import axios from "axios"
import * as Linking from "expo-linking"
import { logOut } from "../../components/Functions"
import { Placeholder, PlaceholderLine, ShineOverlay } from "rn-placeholder"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

import * as Device from "expo-device"

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_COURSE = "SET_COURSE"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_COURSE:
      let result = []
      if (action.data.sections.length != 0) {
        let sortedData = action.data.sections.sort((a, b) => {
          if (a.priority < b.priority) {
            return -1
          }
          return 0
        })

        const hierarchySortFunc = (a, b) => {
          return a.priority < b.priority
        }

        const hierarhySort = (hashArr, key, result) => {
          if (hashArr[key] == undefined) return
          let arr = hashArr[key].sort(hierarchySortFunc)
          for (let i = 0; i < arr.length; i++) {
            result.push(arr[i])
            hierarhySort(hashArr, arr[i].id, result)
          }
          return result
        }

        let arr = sortedData
        let hashArr = {}
        for (let i = 0; i < arr.length; i++) {
          if (hashArr[arr[i].parent_id] == undefined) hashArr[arr[i].parent_id] = []
          hashArr[arr[i].parent_id].push(arr[i])
        }
        result = hierarhySort(hashArr, 0, [])
      }

      return {
        ...state,
        values: {
          ...state.values,
          sections: result,
          course_spot_id: action.data.spot_id,
          spot_license: action.data.spot_id ? action.data.spot_license : null,
        },
      }
    default:
      return state
  }
}

const LoadingPlaceHolder = (props) => {
  return (
    <View>
      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 15 }}>
            <PlaceholderLine height={15} />
          </View>
        </View>
      </Placeholder>
    </View>
  )
}

const CourseScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const role = useSelector((store) => store.user.user_data.role)
  const course_id = props.route.params.course_id
  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      sections: [],
      course_spot_id: null,
      spot_license: null,
    },
  })
  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_course = async () => {
    let url = null

    dispatchFormState({ type: SET_LOADING, loading: true })

    if (role === "customer") {
      url = Config.aioUrl + "/customer/lms/course/get?id=" + course_id
    } else {
      url = Config.aioUrl + "/employee/lms/course/get?id=" + course_id
    }

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_COURSE, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_course()
    })

    return focused
  }, [props.navigation])

  return (
    <View
      style={{
        height: "100%",
        backgroundColor: Colors.bg,
        paddingHorizontal: 25,
      }}
    >
      <Header title="دوره" noNotif noActiveSessions noToggle />
      <View
        style={{
          paddingHorizontal: DESKTOP ? "30%" : 25,
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <View style={{ flex: 1 }}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ paddingBottom: 50, maxHeight: "80vh" }}
          >
            {formState.values.loading && <LoadingPlaceHolder />}

            {formState.values.course_spot_id && !formState.values.loading && (
              <View>
                <InputNote
                  content="این دوره محافظت شده است و شما باید توسط اسپاتپلیر دوره را تماشا کنید. از لینک های زیر اقدام به دانلود نرم افزار پخش دوره کنید. پس از دانلود توسط کد لایسنس داده شده، اقدام به تماشای دوره کنید.
"
                />
                <View
                  style={{
                    flexDirection: "row-reverse",
                    marginBottom: 20,
                    marginTop: 10,
                  }}
                >
                  <Btn
                    title="نسخه ویندوز"
                    color={Colors.blue}
                    onPress={() => {
                      Linking.openURL("https://app.spotplayer.ir/assets/bin/spotplayer/setup.exe")
                    }}
                    wrapperStyle={{ flex: 1 }}
                  />
                  <Btn
                    title="نسخه مک"
                    color={Colors.secondary}
                    onPress={() => {
                      Linking.openURL("https://app.spotplayer.ir/assets/bin/spotplayer/setup.dmg")
                    }}
                    wrapperStyle={{ flex: 1, marginHorizontal: 6 }}
                  />
                  <Btn
                    title="نسخه اندروید"
                    color={Colors.green}
                    onPress={() => {
                      Linking.openURL("https://app.spotplayer.ir/assets/bin/spotplayer/setup.apk")
                    }}
                    wrapperStyle={{ flex: 1 }}
                  />
                </View>
                <InputNote title="کد لایسنس" content={formState.values.spot_license} />
                <InputNote
                  title="نکته مهم"
                  backgroundColor={Colors.primary}
                  textColor="#fff"
                  icon="warning-outline"
                  content="این لایسنس فقط برای یک نفر و یک دستگاه قابل استفاده است. قبل از استفاده از این کد به خوبی تصمیم بگیرید. پس از استفاده از کد، صدور مجدد لایسنس مستلزم پرداخت هزینه خواهد بود."
                />
              </View>
            )}

            {!formState.values.course_spot_id &&
              !formState.values.loading &&
              (Device.osName?.toUpperCase() === "IOS"
                ? formState.values.sections?.reverse()
                : formState.values.sections
              ).map((item) => (
                <TouchableOpacity
                  disabled={!item.has_content}
                  key={item.id}
                  style={{
                    width: "100%",
                    backgroundColor: !item.has_content ? "transparent" : "#F2F2F2",
                    borderRadius: 15,
                    paddingHorizontal: 20,
                    paddingTop: 20,
                    paddingBottom: !item.has_content ? 5 : 20,
                    marginBottom: 10,
                    flexDirection: "row-reverse",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  onPress={() => {
                    props.navigation.navigate("Lesson", { section_id: item.id })

                    if (role === "customer") {
                      props.navigation.navigate("Lesson", {
                        section_id: item.id,
                      })
                    } else {
                      props.navigation.navigate("MyCourseLesson", {
                        section_id: item.id,
                      })
                    }
                  }}
                >
                  <Text
                    style={{
                      fontFamily: !item.has_content ? "IranSans-Medium" : "IranSans-Regular",
                      fontSize: !item.has_content ? 16 : 14,
                      color: Colors.text,
                      marginRight: 5,
                    }}
                  >
                    {item.title}
                  </Text>
                </TouchableOpacity>
              ))}
          </ScrollView>
        </View>
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default CourseScreen
