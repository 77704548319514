import { useReducer, useCallback, useEffect } from "react"
import { View, Text, ScrollView, Dimensions } from "react-native"
import { useSelector } from "react-redux"
import Colors from "../../constants/Colors"
import Header from "../../components/Header"
import Config from "../../constants/Config"
import Input from "../../components/Input"
import Picker from "../../components/Picker"
import Btn from "../../components/Btn"
import axios from "axios"
import { logOut } from "../../components/Functions"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { convertToRaw, EditorState, ContentState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { CommonActions } from "@react-navigation/native"
import TextEditor from "../../components/TextEditor"
import { Placeholder, PlaceholderLine, ShineOverlay } from "rn-placeholder"
const DESKTOP = Dimensions.get("window").width > 800 ? true : false

const INPUT_CHANGE = "INPUT_CHANGE"
const SET_LOADING = "SET_LOADING"
const SET_SECTION = "SET_SECTION"
const SET_COURSE = "SET_COURSE"
const formReducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE:
      if (action.id == "content") {
        return {
          ...state,
          values: {
            ...state.values,
            content: action.value,
            text_content: action.text_content,
          },
        }
      }
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      }
    case SET_LOADING:
      return {
        ...state,
        values: {
          ...state.values,
          loading: action.loading,
        },
      }
    case SET_SECTION:
      let got_section = action.data

      const has_content =
        got_section.has_content == "0" ? { item: "خیر", id: "0" } : { item: "بله", id: "1" }

      const content_category =
        got_section.content_category === "VIDEO"
          ? { item: "ویدیو", id: "VIDEO" }
          : { item: "متن", id: "TEXT" }

      const parent = got_section.parent?.item ? got_section.parent : { item: "هیچ کدام", id: 0 }

      const blocksFromHtml = htmlToDraft(action.data.text_content || "")
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
      const editorState = EditorState.createWithContent(contentState)
      const rawContentState = convertToRaw(editorState.getCurrentContent())
      const markup = draftToHtml(rawContentState)

      console.log()

      return {
        ...state,
        values: {
          ...state.values,
          section: got_section,
          has_content: has_content,
          content_category: content_category,
          parent: parent,

          content: editorState,
          text_content: action.data.text_content || "",
        },
      }
    case SET_COURSE:
      let OldsortedData = action.data.sections.sort((a, b) => {
        if (a.priority < b.priority) {
          return -1
        }
        return 0
      })
      let sortedData = []
      for (let i = 0; i < OldsortedData.length; i++) {
        if (OldsortedData[i].parent_id == 0) {
          sortedData.push(OldsortedData[i])
        }
      }
      sortedData = sortedData.map(({ title: item, ...rest }) => ({
        item,
        ...rest,
      }))

      return {
        ...state,
        values: {
          ...state.values,
          sections: sortedData,
        },
      }
    default:
      return state
  }
}

const LoadingPlaceHolder = (props) => {
  return (
    <View>
      <Placeholder Animation={ShineOverlay}>
        <View
          style={{
            width: "100%",
            backgroundColor: "#f9f9f9",
            borderRadius: 15,
            paddingVertical: 20,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 10,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View style={{ flex: 7, marginTop: 5 }}>
            <PlaceholderLine height={35} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
            <PlaceholderLine height={15} />
          </View>
        </View>
      </Placeholder>
    </View>
  )
}

const SectionScreen = (props) => {
  const token = useSelector((store) => store.user.token)
  const course_id = props.route.params.course_id
  const section_id = props.route.params.section_id

  const [formState, dispatchFormState] = useReducer(formReducer, {
    values: {
      loading: false,
      section: null,
      sections: [],
      //? Must send ==>>
      title: "",
      has_content: "",
      parent: "",
      content_category: "",
      video_url: "",
      text_content: "",
      content: "",
      section_time: "",
    },
  })

  const onEditorStateChange = (editorState) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(rawContentState)

    dispatchFormState({
      type: INPUT_CHANGE,
      id: "content",
      value: editorState,
      text_content: markup,
    })
  }

  const inputChangeHandler = useCallback(
    // You don't need any changes
    (id, value) => {
      dispatchFormState({ type: INPUT_CHANGE, id, value })
    },
    [dispatchFormState]
  )

  const get_section = async () => {
    const url = Config.aioUrl + "/lms/section/get?id=" + section_id + "&course_id=" + course_id

    // const url = "https://dev-api.aminh.pro/aminh/get-sections"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }
    dispatchFormState({ type: SET_LOADING, loading: true })
    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_SECTION, data: response.data.data })
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  const get_course = async () => {
    const url = Config.aioUrl + "/lms/course/get?id=" + course_id

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    axios({
      method: "get",
      url: url,
      headers: reqHedear,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }
        dispatchFormState({ type: SET_COURSE, data: response.data.data })
      })
      .catch(function (error) {})
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_course()
      if (section_id) get_section()
    })

    return focused
  }, [props.navigation])

  const set_section = async () => {
    const url = Config.aioUrl + "/lms/section/set"

    let reqHedear = {
      Authorization: "Bearer " + token,
    }

    let formdata = new FormData()

    formdata.append("title", formState.values.title)

    formdata.append("has_content", parseInt(formState.values.has_content.id))

    formdata.append("parent_id", formState.values.parent.id)

    formdata.append("content_category", formState.values.content_category.id)

    if (formState.values.content_category.id == "VIDEO") {
      formdata.append("video_url", formState.values.video_url)
    }

    if (formState.values.content_category.id == "TEXT") {
      formdata.append("text_content", formState.values.text_content)
    }

    formdata.append("section_time", formState.values.section_time)

    formdata.append("course_id", course_id)

    if (section_id) {
      formdata.append("section_id", section_id)
    }

    dispatchFormState({ type: SET_LOADING, loading: true })

    axios({
      method: "post",
      url: url,
      headers: reqHedear,
      data: formdata,
    })
      .then(function (response) {
        if (response.data.code == 401) {
          alert("نیاز است مجددا به سیستم ورود کنید.")
          logOut()
        }

        dispatchFormState({ type: SET_LOADING, loading: false })
        if (section_id) get_section()
        alert(response.data.msg)
      })
      .catch(function (error) {
        dispatchFormState({ type: SET_LOADING, loading: false })
      })
  }

  useEffect(() => {
    const focused = props.navigation.addListener("focus", () => {
      get_course()
      if (section_id) get_section()
    })

    return focused
  }, [props.navigation])

  console.log(formState.values)

  return (
    <View
      style={{
        paddingHorizontal: 25,
        height: "100%",
        backgroundColor: Colors.bg,
      }}
    >
      <Header title={section_id ? "ویرایش بخش" : "افزودن بخش"} noNotif noActiveSessions noToggle />

      <View
        style={{
          paddingHorizontal: DESKTOP ? "15%" : 25,
          height: "100%",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ flex: 1, paddingBottom: 15, maxHeight: "80vh" }}
        >
          {formState.values.loading ? (
            <LoadingPlaceHolder />
          ) : (
            <>
              <Input
                label="عنوان"
                id="title"
                onChange={inputChangeHandler}
                insert={formState.values.section ? formState.values.section.title : false}
              />

              {formState.values.sections && (
                <Picker
                  label="والد"
                  id="parent"
                  onChange={inputChangeHandler}
                  options={[{ item: "هیچ کدام", id: 0 }, ...formState.values.sections]}
                  insert={formState.values.parent}
                />
              )}

              <Picker
                label="آیا بخش محتوایی دارد؟"
                id="has_content"
                onChange={inputChangeHandler}
                options={[
                  { item: "خیر", id: "0" },
                  { item: "بله", id: "1" },
                ]}
                insert={formState.values.has_content ? formState.values.has_content : false}
              />

              {formState.values.has_content.id == "1" && (
                <Picker
                  label="نوع محتوا"
                  id="content_category"
                  onChange={inputChangeHandler}
                  options={[
                    { item: "ویدیو", id: "VIDEO" },
                    { item: "متن", id: "TEXT" },
                  ]}
                  insert={
                    formState.values.content_category ? formState.values.content_category : false
                  }
                  noSearch
                />
              )}

              {formState.values.content_category.id == "TEXT" && (
                <TextEditor
                  value={formState.values.text_content}
                  setValue={(value) =>
                    dispatchFormState({
                      type: INPUT_CHANGE,
                      id: "text_content",
                      value: value,
                    })
                  }
                />
              )}

              {formState.values.content_category.id == "VIDEO" && (
                <Input
                  label="آدرس ویدیو"
                  id="video_url"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.section && formState.values.section.has_content
                      ? formState.values.section.video_url
                      : false
                  }
                />
              )}

              {formState.values.has_content.id == "1" && (
                <Input
                  label="زمان بخش به دقیقه"
                  id="section_time"
                  onChange={inputChangeHandler}
                  insert={
                    formState.values.section && formState.values.section.section_time
                      ? formState.values.section.section_time
                      : false
                  }
                />
              )}

              <Btn
                title={section_id ? "ثبت تغییرات" : "افزودن بخش"}
                onPress={() => {
                  set_section()
                }}
                loading={formState.values.loading}
                wrapperStyle={{ marginTop: 30 }}
              />
            </>
          )}
        </ScrollView>
      </View>
    </View>
  )
}

export const screenOptions = {
  headerShown: false,
  drawerPosition: "right",
}

export default SectionScreen
